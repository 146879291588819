@import '../../sass/colors.scss';

.team-section {
    .section-title {
        h2 {
            max-width: inherit;
        }
    }

    .team-card {
        text-align: center;
        margin-bottom: 30px;

        .team-img {
            margin-bottom: 20px;
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: #DBEFFA;
                content: '';
                z-index: -1;
                border-radius: 10px;
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

// Small Device Responsive
@media (max-width: 575px) {
    .team-section {
        .pt-5 {
            padding-top: 0 !important;
        }
    }
}